import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import ReactDOM from 'react-dom/client';
import { motion, AnimatePresence } from 'framer-motion';
import { Step1Form, Step2Form, Error } from '~/javascripts/signUp';

const SignUpForm = ({
  translation: t,
  affiliate_code,
  show_login_links,
  show_maintenance,
}) => {
  const [status, setStatus] = useState('step-1');
  const [error, setError] = useState(null);

  const [userForm, setUserForm] = useState({
    email: '',
    password: '',
    affiliate_code: affiliate_code,
  });

  const proceedStep2 = () => {
    setStatus('step-2');
  };

  const backToStep1 = () => {
    // When we hit back button, the verification form gets sent out
    setStatus('step-1');
  };

  const clearError = () => {
    setError(null);
  };

  return (
    <div className="grid grid-rows-1 grid-cols-1 relative">
      {error &&
        createPortal(
          <Error message={error} clearError={clearError} />,
          document.getElementById('sign-up-section')
        )}
      <AnimatePresence mode="popLayout">
        <motion.div
          key={status}
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -50 }}
          transition={{ duration: 0.5 }}
        >
          {status === 'step-1' && (
            <Step1Form
              t={t}
              show_login_links={show_login_links}
              show_maintenance={show_maintenance}
              userForm={userForm}
              setError={setError}
              setUserForm={setUserForm}
              proceedStep2={proceedStep2}
            />
          )}
          {status === 'step-2' && (
            <Step2Form
              t={t}
              userForm={userForm}
              setUserForm={setUserForm}
              backToStep1={backToStep1}
              setError={setError}
            />
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

// Changed to use class to allow multiple sign up forms to exist on one page
// Does mean that they share duplicate ids, which is mainly a problem for GA stats tracking since nothing else uses the ID at the moment

const nodes = document.getElementsByClassName('sign-up-root'); 
for (let node of nodes) {
  const data = JSON.parse(node.getAttribute('data'));
  const root = ReactDOM.createRoot(node);
  root.render(<SignUpForm {...data} />);
}
