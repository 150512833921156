import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { motion, AnimatePresence } from "framer-motion"
import { handleVerificationEmail } from "./handle_verification_email";
import { hasAlphabet, hasNumber, matchLength } from "./utils";
import { turnstileChallenge } from "../turnstile.js"

const InputError = ({
  validation, message
}) => {
  return (
    <p className={`mb-2 text-sm flex items-center transform duration-600 gap-1 ${ validation ? 'text-green-600' : 'text-zinc-600' }`}>
      <AnimatePresence>
        {validation && (
          <motion.svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-green-600 w-5 h-5"
            initial={{ opacity: 0, x: -10, rotate: -180}}
            animate={{ opacity: 1, x: 0, rotate: 0, transition: {
              duration: 0.4
            } }}
            exit={{ opacity: 0, x: -10, rotate: 180 }}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </motion.svg>
        )}
      </AnimatePresence>
      { message }
    </p>
  )
}

const Step1Form = ({ t, show_login_links, show_maintenance, userForm, setUserForm, proceedStep2, setError }) => {
  const [bonusCode, showBonusCode] = useState(!!userForm.affiliate_code);
  const [isFormLoading, setFormLoading] = useState(false)

  const {
    register,
    watch,
    handleSubmit,
    formState: {
      isValid
    }
  } = useForm({
    defaultValues: userForm,
    mode: 'onChange'
  });


  const watchPassword = watch('password')

  const onSubmit = async ({
    email,
    password,
    affiliate_code
  }) => {
    setFormLoading(true)
    setUserForm({
      email,
      password,
      affiliate_code
    });

    try {
      const turnstileToken = await turnstileChallenge("send-code");  
      handleVerificationEmail(email, turnstileToken).then((res) => {
        setFormLoading(false)
        if (res.success) {
          proceedStep2();
        } else {
          setError(res.description)
        }
      });
    } catch (error) {
      setError(error)
      setFormLoading(false)
    }
  }


  const SubmitButton = () => {
    return (
      <button
        type="submit"
        id="signup-button-submit-step-1"
        className="disabled:bg-zinc-800 disabled:cursor-not-allowed disabled:hover:bg-zinc-800 cursor-pointer font-semibold py-4 w-full bg-pink-600 rounded-md cursor-pointer hover:bg-pink-700 transition-colors group" disabled={!isValid}>
        {
          isFormLoading ? (
            <div className="flex gap-2 justify-center items-center">
              <svg className="animate-spin inline h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              { t.submitting }
            </div>
          ) : (
            <div className="group-disabled:text-zinc-700 text-whtie">{ t.submit }</div>
          )
        }
      </button>
    )
  }

  return (
    <>
    { show_maintenance ? 
      (
        <div className="mt-4 row-start-1 col-start-1 transition-opacity duration-700 relative">
          <div className="p-5 grid place-items-center">
            <div className="text-center">
              <p className="mt-4">{ t.maintenance_1 }</p>
              <p className="mt-2">{ t.maintenance_2 }</p>
            </div>
          </div>
        </div>
      ) : (
        <div id="signupStep1" className="mt-4 row-start-1 col-start-1 transition-opacity duration-700">
          { show_login_links &&
            <p> { t.have_account } <a href="/login" className="text-pink-500 mb-12">{ t.sign_in }</a></p>
          }
          <form onSubmit={handleSubmit(onSubmit)} id="signup-step1Form">  {/* Being used to track on GA */}
            {/* email */}
            <div>
              <label className="block mt-8 font-medium" htmlFor="username">
                { t.email }
              </label>
              <input
                name="email"
                type="email"
                {...register("email", {
                  required: "required",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Invalid Email",
                  }
                })}
                className="block h-12 w-full bg-black/20 outline-0 rounded p-4 mt-2 focus:ring-blue-500"
              />
            </div>

            {/* password */}
            <div>
              <label className="block mt-6 font-medium" htmlFor="password">
                { t.password }
              </label>
              <input
                name="password"
                type="password"
                className="block h-12 w-full bg-black/20 rounded p-4 mt-2 focus:ring-blue-500"
                {...register("password", {
                  required: true,
                  maxLength: 20,
                  minLength: 8,
                  pattern: {
                    value: /(?=.*[0-9])(?=.*[a-z]).{8,20}$/,
                  }
                })}
              />
              <div className="p-2 mt-2">
                <InputError validation={ hasAlphabet(watchPassword) } message={ t.password_lowercase_character } />
                <InputError validation={ hasNumber(watchPassword) } message={ t.password_digit } />
                <InputError validation={ matchLength(watchPassword) } message={ t.password_length } />
              </div>
            </div>

            {/* bonus */}
            <div>
              { bonusCode ? (
                <>
                  <label className="mt-8 block font-semibold" htmlFor="affiliate_code">
                    { t.bonus_code }
                  </label>
                  <div className="mt-4 mb-12" id="step1FormBonusContainer">
                    <input
                      name="affiliate_code"
                      type="text"
                      className="disabled:bg-zinc-800 disabled:cursor-not-allowed disabled:text-zinc-500 block h-12 w-full bg-black/20 rounded p-4 mt-2 focus:ring-blue-500"
                      readOnly={ !!userForm.affiliate_code }
                      disabled={ !!userForm.affiliate_code }
                      {...register("affiliate_code", {})}
                    />
                  </div>
                </>
                ) : (
                  <p onClick={() => showBonusCode(true)} className="text-sm cursor-pointer mt-8 mb-4 hover:text-zinc-200 text-zinc-400" id="step1FormBonusTrigger">
                    { t.have_bonus_code }
                  </p>
                )}
            </div>

            {/* submit */}
            <SubmitButton />

          </form>

          <p className="text-sm text-zinc-500 mt-8 leading-normal">
            { t.terms }
          </p>
        </div>
      )
    }
    </>
  )
}

export default Step1Form;
