const hasNumber = (password) => {
  return /\d/.test(password);
}

const hasAlphabet = (password) => {
  return /[a-zA-Z]/.test(password);
}

const matchLength = (password) => {
  return password.length >= 8 && password.length <= 20;
}

export { hasNumber, hasAlphabet, matchLength }
