import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion"

const Error = ({ message, clearError }) => {
  if (!message) {
    return null;
  }

  const DEFAULT_CODE_COUNTDOWN_SECONDS = 8;
  const [countdownSeconds, setCountdownSeconds] = useState(DEFAULT_CODE_COUNTDOWN_SECONDS + 1);

  useEffect(() => {
    let interval;

    if (countdownSeconds > 0) {
      interval = setInterval(() => {
        setCountdownSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else {
      clearError();
    }

    return () => clearInterval(interval);
  }, [countdownSeconds]);

  return (
    <AnimatePresence>
      {countdownSeconds > 1 && (
        <motion.div
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 100 }}
          className="absolute text-sm top-3 right-3 z-50 bg-red-500 p-4 rounded w-full max-w-xs"
        >
          { message }
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Error;
