import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { handleVerificationEmail } from "./handle_verification_email";

const VerificationCodeSender = ({ t, email, setError }) => {

  const DEFAULT_CODE_COUNTDOWN_SECONDS = 60;
  const [countdownSeconds, setCountdownSeconds] = useState(DEFAULT_CODE_COUNTDOWN_SECONDS);
  const [isLoading, setIsLoading] = useState(false);

  const resendVerificationCode = () => {
    setIsLoading(true)
    handleVerificationEmail(email).then((res) => {
      setIsLoading(false)

      setCountdownSeconds(DEFAULT_CODE_COUNTDOWN_SECONDS)
      if(!res.success) {
        setError(res.description)
      }
    })
  };

  useEffect(() => {
    let interval;

    if (countdownSeconds > 0) {
      interval = setInterval(() => {
        setCountdownSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [countdownSeconds]);

  const renderCountdownMessage = () => {
    const updatedString = t.retry_in_seconds.replace(/%\{number\}/g, `${countdownSeconds}`);
    return (
      <p className="text-zinc-400 py-2 text-sm text-center">
        {t.email_verification_code_resend_success} {updatedString}
      </p>
    );
  };

  const renderResendLink = () => {
    return (
      <a onClick={resendVerificationCode} className="style-none block text-center py-2 text-sm cursor-pointer hover:text-zinc-200 text-zinc-400">
        {t.email_verification_code_resend}
      </a>
    );
  };

  const renderLoadingText = () => {
    return (
      <p className="text-zinc-400 italic py-2 text-sm text-center">
        {t.email_verification_code_sending}
      </p>
    );
  };

  if (isLoading) {
    return renderLoadingText();
  }

  if (countdownSeconds > 0) {
    return renderCountdownMessage();
  } else {
    return renderResendLink();
  }
};

const Step2Form = ({t, userForm, backToStep1, setError}) => {
  const {
    register,
    handleSubmit,
    formState: {
      isValid
    }
  } = useForm({
    mode: 'onChange'
  });

  const [isFormLoading, setFormLoading] = useState(false)

  const onSubmit = async ({
    verificationCode
  }) => {
    setFormLoading(true)

    const csrfToken = document.querySelector("[name='csrf-token']").content

    const res = await fetch('/sign-up', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      body: JSON.stringify(
        {
          ...userForm,
          verification_code: verificationCode
        }
      )
    })


    if (res.ok) {
      window.location.assign('/sign-up/success')
    } else {
      const error = await res.json();
      setError(error.description)
      setFormLoading(false)
    }
  }

  const SubmitButton = () => {
    return (
      <button
        type="submit"
        id="signup-button-submit-step-2"
        className="disabled:bg-zinc-800 disabled:cursor-not-allowed disabled:hover:bg-zinc-800 cursor-pointer font-semibold py-4 w-full bg-pink-600 rounded-md cursor-pointer hover:bg-pink-700 transition-colors group"
        disabled={!isValid}
      >
        {
          isFormLoading ? (
            <div className="flex gap-2 justify-center items-center">
              <svg className="animate-spin inline h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              { t.submitting }
            </div>
          ) : (
            <div className="group-disabled:text-zinc-700 text-whtie">Verify & Create Account</div>
          )
        }
      </button>
    )
  }

  return (
    <div id="signupStep2" className="mt-8 row-start-1 col-start-1">
      <div className="items-center gap-2 group cursor-pointer rounded py-2 pl-2 pr-4 bg-zinc-700 hover:bg-zinc-600 inline-flex transition-colors" id="backToStep1">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 text-zinc-300 group-hover:text-zinc-100 transition-colors">
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
        </svg>
        <p onClick={backToStep1} className="text-sm text-zinc-300 group-hover:text-zinc-100 transition-colors">
          { t.back_step1 }
        </p>
      </div>

      <p className="mt-8 text-base text-zinc-400">
        { t.email_verification_code_sent } <span className="text-white" id="step2Email">{userForm.email}</span>
      </p>
      <p className="mt-4 text-base text-zinc-400">
        { t.email_verification_code_check }
      </p>

      <form onSubmit={handleSubmit(onSubmit)} className="mt-8" id="signup-step2Form">
        {/* Being used to track on GA */}
        <label className="block font-semibold" htmlFor="verification_code">
          { t.verification_code }
        </label>
        <input
          name="verification_code"
          type="text"
          id="step2FormCode"
          {
            ...register('verificationCode', {
              required: "required"
            })
          }
          className="block h-12 w-full bg-black/20 outline-0 rounded p-4 mt-2 focus:ring-blue-500"
        />

        <div className="mt-4 mb-8">
          <VerificationCodeSender
            t={t}
            email={userForm["email"]}
            setError={setError}
          />
        </div>

        <SubmitButton />
      </form>

      <p className="text-sm text-zinc-500 mt-8 leading-normal">
        { t.terms }
      </p>
    </div>
  )
}

export default Step2Form;
