const sendVerificationEmail = async (email, turnstileToken) => {
  const csrfToken = document.querySelector("[name='csrf-token']").content
  const form = new FormData()
  form.set('turnstile_token', turnstileToken)
  form.set('authenticity_token', csrfToken)
  form.set('email', email)

  return await fetch('/email-verification-code', {
    method: 'POST',
    body: form
  })
}

export const handleVerificationEmail = (email, turnstileToken) => {
  const res = sendVerificationEmail(email, turnstileToken).then((res) => {
    return res.json();
  }).then((r) => {
    return r
  })
  return res
}
